$.fn.nl_google_map = function(event,data,options) {
    return this.each(function() {
        let $this = $(this);
        if (!$this.length) {
            return false;
        }
        if (event === 'init') {
            nl_google_map_init();
        }
        else if (event === 'markers_add') {
            nl_google_map_markers_add_method(data);
        }

        else if (event === 'markers_remove') {
            nl_google_map_markers_remove_method();
        }

        else if (event === 'markers_reload') {
            nl_google_map_markers_remove_method();
            nl_google_map_markers_add_method();
        }

        else if (event === 'position') {
            nl_google_map_position_method(data);
        }

        else if (event === 'marker_click') {
            nl_google_map_marker_click_method(data);
        }

        function nl_google_map_init() {
            let $zoom = $this.data("zoom"),
                $coords = $this.data('coords').split(/[\s,]+/),
                $markers = $this.data("markers"),
                $markers_temp = [],
                $map_options = {
                    zoom: $zoom,
                    mapTypeControl: false,
                    options,
                    center: {
                        lat: parseFloat($coords[0]), lng: parseFloat($coords[1])
                    },
                    styles: [
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#a0d6d1"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#dedede"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#dedede"
                                },
                                {
                                    "lightness": 29
                                },
                                {
                                    "weight": 0.2
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dedede"
                                },
                                {
                                    "lightness": 18
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f1f1f1"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "saturation": 36
                                },
                                {
                                    "color": "#333333"
                                },
                                {
                                    "lightness": 40
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f2f2f2"
                                },
                                {
                                    "lightness": 19
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 17
                                },
                                {
                                    "weight": 1.2
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.country",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#265654"
                                }
                            ]
                        },
                    ]
                };

            let $map = new google.maps.Map($this[0], $map_options);

        function nl_google_map_position(data) {
                let $location = data.split(/[\s,]+/);
                let latLng = new google.maps.LatLng($location[0],$location[1]);
                $map.panTo(latLng);
                $map.setZoom(17);
            }
            function nl_google_map_markers_add(callback) {
                let $bounds = new google.maps.LatLngBounds();
                let $infoWindow = new google.maps.InfoWindow({
                    content: "",
                    maxWidth: 190
                });

                if ($this.filter("[data-marker]").length) {
                    let $main_marker_position = new google.maps.LatLng(parseFloat($coords[0]), parseFloat($coords[1]));

                    let $main_marker = new google.maps.Marker({
                        position: $main_marker_position,
                        map: $map,
                        zIndex: 9999
                    });

                    if ($this.data("marker-url").length > 0) {
                        google.maps.event.addListener($main_marker, 'click', function(){
                            window.location.href = $this.data("marker-url")
                        });
                    }

                    $markers_temp.push($main_marker);
                    $bounds.extend($main_marker_position);
                }
                if ($this.filter("[data-markers]").length) {
                    $markers = $this.data("markers");
                    for (let i = 0; i < $markers.length; i++) {
                        let $marker = $markers[i],
                            $coords = $marker.coords.split(/[\s,]+/),
                            $position = new google.maps.LatLng(parseFloat($coords[0]), parseFloat($coords[1])),
                            $icon = new google.maps.Marker({
                                position: $position,
                                map: $map,
                                title: $marker.name,
                                logo: $marker.logo,
                                icon: {
                                    path: "M32 2a20 20 0 0 0-20 20c0 18 20 40 20 40s20-22 20-40A20 20 0 0 0 32 2zm0 28a8 8 0 1 1 8-8 8 8 0 0 1-8 8z",
                                    fillColor: $this.data("fill"),
                                    fillOpacity: 1,
                                    strokeWeight: 0,
                                    scale: 0.75,
                                    anchor: new google.maps.Point(32, 64)
                                }
                            });

                        $markers_temp.push($icon);
                        $bounds.extend($position);

                        function showInfo($marker) {
                            return function () {
                                let $logo = "";
                                if($marker.logo) {
                                    $logo = "<div style='margin-bottom: 0.25em'><img src=\""+$marker.logo+"\" alt=\""+$marker.title+"\" /></div>";
                                }
                                let $content = $logo+`<h4 class="part_ui_title"><span>${$marker.title}</span></h4>`;
                                $map.panTo($marker.position);
                                $infoWindow.setContent($content);
                                $infoWindow.open($map,$marker);
                            }
                        }

                        google.maps.event.addListener($icon, 'click', showInfo($icon));
                        //
                        // google.maps.event.addListener($infoWindow, 'domready', function() {
                        //     $('.part_ui_info').closest('.gm-style-iw').parent().addClass('gm-custom-iw');
                        // });

                        if (callback) {
                            callback($icon, $markers_temp, $map, $infoWindow);
                        }
                    }

                    let $markerCluster = new MarkerClusterer($map, $markers_temp, {imagePath: '/img/markers/m'});

                    google.maps.event.addListenerOnce($map, 'bounds_changed', function() {
                        if(this.getZoom() > $zoom) {
                            this.setZoom($zoom);
                        }
                    });

                    $map.fitBounds($bounds);
                }

                function nl_google_map_markers_remove() {
                    for (let i = 0; i < $markers_temp.length; i++) {
                        $markers_temp[i].setMap(null);
                    }

                    $markers_temp = [];
                }
                window.nl_google_map_markers_remove_method = nl_google_map_markers_remove;
            }
            function nl_google_map_marker_click(index) {
                google.maps.event.trigger($markers_temp[index], 'click');
            }

            window.nl_google_map_markers_add_method = nl_google_map_markers_add;
            window.nl_google_map_position_method = nl_google_map_position;
            window.nl_google_map_marker_click_method = nl_google_map_marker_click;
        }
    });
};