(function ($) {
    let $fn = $(".comp_slider_services");

    if ($fn.length) {
        let $slider = $fn.find("[data-slider]"),
            $nav = $fn.find(".wrp_comp_nav");

        if($slider.children().length > 3){
            $.lib_flickity(function () {
                $slider.flickity({
                    pageDots: false,
                    wrapAround: true,
                    prevNextButtons: false,
                    cellAlign: "left"
                });

                $nav.on("click","[data-arrow]",function (e) {
                    e.stopPropagation();
                    $slider.flickity($(this).data("arrow"));
                })
            });
        }
        else {
            $nav.remove();
        }
    }
})(jQuery);